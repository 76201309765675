jQuery(document).ready(function($) {
  let mm = gsap.matchMedia()

  mm.add({
    isDesktop: `(min-width: 768.02px)`,
    isMobile: `(max-width: 768px)`,
  },
  (context) => {
    let {isDesktop, isMobile} = context.conditions;

    gsap.timeline({
      onComplete() {
        $(document.body).removeClass('stop-scroll')
      },
    })
      .to('.home_header .cover', {
        xPercent: 100,
        duration: 1.2,
        ease: 'power2.out',
        onComplete() {
          $('.lines').addClass('hide')
        }
      })
      .fromTo('.home_header .main_video', {
        clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
      }, {
        duration: 1.2,
        ease: 'power2.out',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      }, '>-.4')
      .fromTo('.home_header .main_video', {
        scale: 0.6,
      }, {
        duration: 1.2,
        ease: 'power2.inOut',
        scale: 1,
        onComplete() {
          document.querySelector('.home_header .main_video').play()
        }
      }, '>-.5')
      .fromTo('.menu_bar .menu_button', {
        y: isDesktop ? -130 : 100,
      }, {
        duration: 1,
        ease: 'power2.inOut',
        y: 0,
      }, '>-.7')
      .fromTo('.menu_bar .menu_cta', {
        y: isDesktop ? -130 : 100,
      }, {
        duration: 1,
        ease: 'power2.inOut',
        y: 0,
      }, '>-.9')
      .fromTo('.menu_top_logo', {
        y: -130,
      }, {
        duration: 1,
        ease: 'power2.inOut',
        y: 0,
      }, '>-.9')
      .fromTo('.header_overlay .title', {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        yPercent: 100,
      }, {
        duration: 1.2,
        ease: 'power2.out',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        yPercent: 0,
      }, '>-.4')
      .fromTo('.header_overlay .subtitle', {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
        yPercent: 100,
      }, {
        duration: 1.2,
        ease: 'power2.out',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        yPercent: 0,
      }, '>-.8')
      .fromTo('.header_overlay .main_arrow', {
        clipPath: 'circle(0% at 50% 50%)',
      }, {
        duration: 1.4,
        ease: 'power2.out',
        clipPath: 'circle(50% at 50% 50%)',
      }, '>-.8')
  })
})

//intro aninmation
function homeHeaderTrigger() {
  let mainArrowSetter = gsap.utils.pipe(
    gsap.quickSetter("#main_arrow", "rotate", "deg")
  );

  ScrollTrigger.create({
    trigger: '.home_header',
    start: 'top top',
    end: 'bottom top',
    // markers: true,
    onUpdate(self) {
      mainArrowSetter(self.progress * 180 + 152)

      // alternarive set for arrow because quicksetter doesnt work as intended
      gsap.set('#arrow', {
        transform: `rotate(${self.progress * -180 - 152}deg)`
      })
    },
  })
}

//philosophy animation
function philosophyTrigger() {
  let mm = gsap.matchMedia()

  mm.add({
    isDesktop: `(min-width: 1024.02px)`,
    isTablet: `(min-width: 768.02px) and (max-width: 1024px)`,
    isMobile: `(max-width: 768px)`,
  },
  (context) => {
    let {isDesktop, isTablet, isMobile} = context.conditions;
    
    if (isDesktop) {
      ///top
      gsap.timeline({
        scrollTrigger: {
          trigger: '.philosophy .top',
          toggleActions: "play none none reverse",
          start: '+30% bottom',
          // markers: true,
        }
      })
      .fromTo('.philosophy .top .fade-in', {
        opacity: 0,
      }, {
        opacity: 1,
        duration: 1,
        ease: 'power2.out',
      })
      .fromTo('.philosophy .top .reveal-up span', {
        yPercent: 100,
      }, {
        duration: 1.8,
        ease: 'power2.out',
        yPercent: 0,
      }, '>-.6')
      .fromTo('.philosophy .top .img-reveal', {
        opacity: 0,
        clipPath: 'inset(5% 5% 5% 5% round 9999px)',
        scale: 1.1,
      }, {
        duration: 1,
        ease: 'power2.in',
        opacity: 1,
        clipPath: 'inset(0% 0% 0% 0% round 9999px)',
        scale: 1,
      }, '<-.3')

      //bottom
      gsap.timeline({
        scrollTrigger: {
          trigger: '.philosophy .bottom',
          toggleActions: "play none none reverse",
          start: '+50% bottom',
          // markers: true,
        }
      })
      .fromTo('.philosophy .bottom', {
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
      }, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        duration: 1.5,
        ease: 'power2.out',
      })
      .fromTo('.philosophy .bottom .text span', {
        yPercent: 100,
      }, {
        duration: 1.8,
        ease: 'power2.out',
        yPercent: 0,
      }, '>-.8')
      .fromTo('.philosophy .bottom .title span', {
        xPercent: -100,
      }, {
        duration: 1.8,
        ease: 'power2.out',
        xPercent: 0,
      }, '<')
      .fromTo('.philosophy .bottom .img-reveal', {
        opacity: 0,
        clipPath: 'inset(5% 5% 5% 5% round 9999px)',
        scale: 1.1,
      }, {
        duration: 1,
        ease: 'power2.in',
        opacity: 1,
        clipPath: 'inset(0% 0% 0% 0% round 9999px)',
        scale: 1,
      }, '<-.3')
    }
  })
}